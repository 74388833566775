import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../apiContext";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import VideoService from "./VideoService";
import ViewForm from "./ViewForm";
import axios from "axios";
import { getToken } from "../../auth/AuthService";
import DeleteBox from "../DeleteBox";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";
import htmlTruncate from "html-truncate";

function TestiVideoApi({ page = 1, pageSize, searchQuery, totalLength }) {
  const { data, error, setData } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const{setIsLoader} = useLoader();
  const [modalData, setModalData] = useState(null);
  const token = getToken();
  const apiBaseUrl = window.API_CONFIG.All_Api.video;

  const closeModal = () => setShowModal(false);
  const modalClose = () => setEditModal(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);

  useEffect(() => {
    try {
      if (!data.video || data.video.length === 0) {
        throw new Error("No data available");
      }

      const filteredData = data.video.filter((item) => {
        const name = item?.title ?? "";
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      });

      const sortedData = filteredData.sort(
        (a, b) =>
          {
            const dateA = a.updatedDate ? new Date(a.updatedDate) : new Date(a.createdDate);
            const dateB = b.updatedDate ? new Date(b.updatedDate) : new Date(b.createdDate);
            return dateB - dateA; 
      }
      );

      setFilteredBlog(sortedData);
      totalLength(filteredData.length);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, [data.video, searchQuery, page, pageSize, totalLength]);

  /* Handle Remove function */

  const closeDelModal = () => setShowDeleteModal(false);

  const performDelete = async () => {
    closeDelModal(); 
    setIsLoader(true);

    try {
      const updatedCareerData = data.video.filter(
        (item) => item.code !== deleteCandidate.code
      );

      const response = await axios.post(
        apiBaseUrl,
        { ViewModels: updatedCareerData },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-access-token": token,
          },
        }
      );
      console.log(response);
      setData((prevData) => ({
        ...prevData,
        video: updatedCareerData,
      }));

      notify.success("Data removed successfully")
    } catch (error) {
      console.error("Error removing data:", error);
      notify.error("Error removing data");
    }
    setIsLoader(false);
  };

  const handleRemove = (dataToRemove) => {
    console.log(dataToRemove);
    setShowDeleteModal(true);
    setDeleteCandidate(dataToRemove);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const items = filteredBlog
      .slice(startIndex, endIndex)
      .map((viewModel, index) => {
        const id = `item-${index}`;
        const fetchData = async () => {
          try {
            setModalData(viewModel);
          } catch (error) {
            console.error(error);
          }
        };
        return (
          <tr key={id}>
            <td
              title="View"
              onClick={() => {
                setShowModal(true);
                fetchData();
              }}
            >
              <FaEye />
            </td>
            <td
              title="Edit"
              onClick={() => {
                setEditModal(true);
                fetchData();
              }}
            >
              <FaEdit />
            </td>
            <td title="Delete" onClick={() => handleRemove(viewModel)}>
              <FaTrashAlt />
            </td>
            <td width="25%">
              {viewModel && viewModel.title
                ? `${viewModel.title.substring(0, 20)}...`
                : "Title not available"}
            </td>
            <td width="38%">
              {viewModel.description ? (
                 <div
                 dangerouslySetInnerHTML={{
                   __html: htmlTruncate(viewModel.description, 50), 
                 }}
               
                 />
              ) : (
                "Description not available"
              )}
            </td>
            <td width="16%">
              {viewModel.updatedDate || viewModel.createdDate}
            </td>
            <td width="10%">
              <span
                className={`status ${viewModel.status ? "active" : "inactive"}`}
              >
                {viewModel.status ? "active" : "inactive"}
              </span>
            </td>
            {showModal && (
              <ViewForm Modalclose={closeModal} modalData={modalData} />
            )}
            {editModal && (
              <VideoService
                closeModal={modalClose}
                modalData={modalData}
                setModalData={setModalData}
                setFilteredBlog={setFilteredBlog}
              />
            )}
          </tr>
        );
      });

    const caseItems = (
      <tbody>
        {filteredBlog.length === 0 ? (
          <tr>
          <td classname ="noFound" colSpan="12">No data found</td>
          </tr>
        ) : (
          items
        )}
        {showDeleteModal && (
          <DeleteBox Modalclose={closeDelModal} handleDelete={performDelete} />
        )}
      </tbody>
    );
    return caseItems;
  }
}

export const VideoLength = () => {
  const { data } = useContext(ApiContext);
  return data.video.length;
};
export default TestiVideoApi;
