import React, { useState, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import { getToken } from "../../auth/AuthService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AddForm from "./AddForm";
import notify from "../../helper/Notify";
import { useLoader } from "../../helper/Loader";
import { isSpecialCharacter } from "../../helper/CommonFunction";

const Service = ({ closeModal, modalData, setModalData }) => {
  const { data, error, setData } = useContext(ApiContext);
  const [formError, setFormError] = useState({});
  const [contentError, setContentError] = useState(null);
  const {setIsLoader} = useLoader();
  const [dataSave, setDataSave] = useState({})
  const apiBaseUrl = window.API_CONFIG.All_Api.career;
  const token = getToken();
  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  const [addCase, setCase] = useState({
    title: "",
    location: "",
    category: 0,
    skills: "",
    code: generateUUID(),
  });

  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  const fieldsArray = [
    "description",
    "responsibilities",
    "knowledgeandskills",
    "educationandexperience",
  ];
  const handleInputChange = async (event, field) => {
    const { value } = event.target;
    
    const updatedValue = field === "skills" ? value.split(",").map(item => item.trim()) : value;
  
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: updatedValue,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: updatedValue,
      }));
    }
  };
  


   // For Jodit Editot use sep. datachange function
   const contentDataChanged = (data, field) => {
    if (data.includes("http:")) {
      setContentError("Please use secure 'https' URLs only");
      return;
    }
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data;
    const h1Count = tempDiv.getElementsByTagName("h1").length;
    if (h1Count > 1) {
      setContentError("Only one <h1> tag is permitted.");
      return;
    }
  
    setContentError(null);
  
    if (modalData) {
      setDataSave((prevDataSave) => ({
        ...prevDataSave,
        [field]: data,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: data,
      }));
    }
  };

  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["title", "location", "category"];
    const validatefield = (field) => {
      if (!modalData && addCase[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
      if (modalData && modalData[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
      if (field === "category" && modalData && modalData[field] === 0) {
        errors[field] = "category must be selected.";
      }
      if (!modalData && addCase && addCase[field] === 0) {
        errors[field] = "category must be selected.";
      }
    };
    fieldarr.forEach(validatefield);

    return errors;
  };
  const handleClear = () => {
    const clearfields = {
      title: "",
      location: "",
      category: 0,
      responsibilities: "",
      knowledgeandskills: "",
      educationandexperience: "",
      skills: "",
      description: "",
      status: false,
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };

  async function processAndUploadImages(modalData, addCase, fieldsArray) {
    for (const field of fieldsArray) {
      const htmlContent = modalData ? modalData[field] : addCase[field];
      if (!htmlContent) {
        continue;
      }
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      const imgElements = Array.from(tempDiv.querySelectorAll("img"));

      const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
        return new Blob([byteArray], { type: mimeType });
      };

      const processImage = async (img) => {
        const src = img.getAttribute("src");
        if (src && src.startsWith("data:image/")) {
          const parts = src.split(";");
          const mimeType = parts[0].split(":")[1];
          const imageData = parts[1].split(",")[1];
          const blob = base64ToBlob(imageData, mimeType);
          const file = new File([blob], "image.png", { type: mimeType });

          const formData = new FormData();
          formData.append("upload", file);
          formData.append(
            "ckCsrfToken",
            "2fDpPNU1D4C9z19x1f0XZTAaH6nZlR8DoiH950V5"
          );

          try {
            const response = await fetch(window.API_CONFIG.imageStringUrl, {
              method: "POST",
              body: formData,
            });

            if (response.ok) {
              const data = await response.json();
              const imgUrl = data.url;
              img.setAttribute("src", imgUrl);
            } else {
              console.error("Error uploading image:", response.statusText);
            }
          } catch (error) {
            console.error("Error processing image:", error);
          }
        }
      };

      const processImagesSequentially = async () => {
        for (const img of imgElements) {
          await processImage(img);
        }
        if (modalData) {
          modalData[field] = tempDiv.innerHTML;
        } else {
          addCase[field] = tempDiv.innerHTML;
        }
      };

      await processImagesSequentially();
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();

    if (Object.keys(formErrors).length > 0) {
      setFormError(formErrors);
      notify.warning();
      return; // Stop form submission if there are validation errors
    }

    setFormError(""); // Clear form errors if there are no validation errors
    setIsLoader(true)
    try {
      if (modalData) {
        const updatedModalData = { ...modalData, ...dataSave };
        await processAndUploadImages(updatedModalData, null, fieldsArray);
        // Updating an existing data entry
        const updatedData = {
          ...updatedModalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const careerData = response.data.data?.ViewModels || [];
          const updatedCareerData = careerData.map((item) =>
            item.code === modalData.code ? { ...item, ...updatedData } : item
          );
          const duplicateItem = careerData.find(
            (item) =>
              item.title === modalData.title && item.code !== modalData.code
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }

          if(contentError){
            notify.error(contentError);
            return;
          }

          if (isSpecialCharacter(modalData.title.charAt(0))){
            notify.error("Name should not start with special character");
               return;
          }

          const updateResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedCareerData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );
          console.log("Data successfully updated:", updateResponse.data);
          if(updateResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            career: updatedCareerData,
          }));
          notify.success(updateResponse.data.message);
          closeModal();

          setCase({}); 
        }
        else{

          notify.error(updateResponse.data.message);
        }
        } catch (error) {
          console.error("Error updating data:", error);
          notify.error(error.message);
        }
      } else {
        // Adding a new data entry
        await processAndUploadImages(null, addCase, fieldsArray);
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const oldData = response.data.data?.ViewModels || [];
          const duplicateItem = oldData.find(
            (item) => item.title === addCase.title
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be added.");
            return;
          }

          if(contentError){
            notify.error(contentError);
            return;
          }

          if (isSpecialCharacter(addCase.title.charAt(0))){
            notify.error("Name should not start with special character");
               return;
          }

          const updatedData = [...oldData, updatedBlogData];

          const addResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("New data added successfully:", addResponse.data);
          if(addResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            career: updatedData,
          }));

          closeModal();
          notify.success(addResponse.data.message);

          setCase({}); 
        }
        else{
          notify.error(addResponse.data.message);
        }
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error("Error occurred. Please try again.");
    }
    finally{
      setIsLoader(false)
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit Career" : "Add New Career"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                careerCategory={data.careerCategory}
                contentDataChanged={contentDataChanged}
                closeModal={closeModal}
                thumbnail_pdf_name={getInputValue("thumbnail_pdf_name")}
                thumbnail_image_name={getInputValue("thumbnail_image_name")}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Service;
