import React, { useContext, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Form,
  Input,
  Label,
  Button,
} from "reactstrap";
import { FaPlus, FaRegTrashCan } from "react-icons/fa6";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getToken } from "../../auth/AuthService";
import { ApiContext } from "../apiContext";
import { useLoader } from "../../helper/Loader";



export default function AddFieldAccordion({ selectedFormType, selectId }) {

  const [open, setOpen] = useState(""); 
    const { data, setData } = useContext(ApiContext);
    const {setIsLoader} = useLoader();
  const token = getToken();
  const [newField, setNewField] = useState({
    label: "",
    name: "",
    type: "text",
    required: false,
    options: [],
  });
  const [newOption, setNewOption] = useState("");
  const apiBaseUrl = window.API_CONFIG.All_Api.formField;
  const toggleAccordion = (id) => {
    setOpen(open === id ? "" : id); // Toggle accordion
  };

  const handleAddOption = () => {
    if (newOption.trim() === "") return;
    setNewField({
      ...newField,
      options: [...newField.options, newOption],
    });
    setNewOption("");
  };

  const handleDeleteOption = (optionIndex) => {
    setNewField({
      ...newField,
      options: newField.options.filter((_, index) => index !== optionIndex),
    });
  };

  const handleSubmit = async () => {
    if (!newField.label || !newField.name) {
      alert("Please fill out all required fields!");
      return;
    }
  
    const updatedFormData = {
      formId: selectId ? selectId : uuidv4(),
      formType: selectedFormType,
      formfields: [newField], // Adding a single field
    };
  
    setIsLoader(true)
    try {
      await axios.post(apiBaseUrl, updatedFormData, {
        headers: { "Content-Type": "application/json", "x-access-token": token },
      });
  
      setData((prevData) => {
        const existingForm = prevData.formField.find((form) => form.formType === selectedFormType);
  
        let updatedForms;
        if (existingForm) {
          updatedForms = prevData.formField.map((form) =>
            form.formType === selectedFormType
              ? { ...form, formfields: [...form.formfields, newField] }
              : form
          );
        } else {
          updatedForms = [...prevData.formField, updatedFormData];
        }
  
        return { ...prevData, formField: updatedForms };
      });
  
      // Reset the input fields
      setNewField({ label: "", name: "", type: "text", required: false, options: [] });
      setOpen("");
  
    } catch (error) {
      console.error("Error saving field:", error);
    }
    finally{
        setIsLoader(false)
    }
  };
  

  return (
    <Accordion open={open} toggle={() => toggleAccordion("add-field")} className="border rounded-lg shadow-sm">
      <AccordionItem>
        <AccordionHeader targetId="add-field"><FaPlus/> <h5 style={{
            margin: 0 , fontSize: "17px", padding: " 0 10px"
        }}>Add New Field </h5></AccordionHeader>
        <AccordionBody accordionId="add-field">
          <Form>
            <div className="row">
              <div className="col-md-6 my-2">
                <Label>Field Label</Label>
                <Input
                  type="text"
                  placeholder="Enter Field Label"
                  value={newField.label}
                  onChange={(e) => setNewField({ ...newField, label: e.target.value })}
                />
              </div>

              <div className="col-md-6 my-2">
                <Label>Field Name (Key)</Label>
                <Input
                  type="text"
                  placeholder="Enter Field Key"
                  value={newField.name}
                  onChange={(e) => setNewField({ ...newField, name: e.target.value })}
                />
              </div>

              <div className="col-md-6 my-2">
                <Label>Field Type</Label>
                <Input
                  type="select"
                  value={newField.type}
                  onChange={(e) => setNewField({ ...newField, type: e.target.value })}
                >
                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="email">Email</option>
                  <option value="date">Date</option>
                  <option value="select">Select</option>
                  <option value="radio">Radio</option>
                </Input>
              </div>

              <div className="col-md-6 my-2 d-flex align-items-center">
                <Input
                  type="checkbox"
                  checked={newField.required}
                  onChange={(e) => setNewField({ ...newField, required: e.target.checked })}
                />
                <Label className="ms-2">Mandatory</Label>
              </div>

              {(newField.type === "select" || newField.type === "radio") && (
                <div className="col-md-12 mt-3 border rounded p-3 bg-light">
                  <Label>Options:</Label>
                  <div className="mb-2">
                    {newField.options.map((option, index) => (
                      <div key={index} className="d-flex justify-content-between align-items-center bg-white p-2 rounded border mb-1">
                        <span>{option}</span>
                        <Button color="danger" size="sm" onClick={() => handleDeleteOption(index)}>
                          <FaRegTrashCan size={16} />
                        </Button>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex">
                    <Input
                      type="text"
                      placeholder="Add option"
                      value={newOption}
                      onChange={(e) => setNewOption(e.target.value)}
                    />
                    <Button color="success" size="sm" className="ms-2" onClick={handleAddOption}>
                      Add
                    </Button>
                  </div>
                </div>
              )}

              <div className="mt-4 text-end">
                <Button color="primary" onClick={handleSubmit}>
                  <FaPlus size={16} className="me-2" />
                  Add Field
                </Button>
              </div>
            </div>
          </Form>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
}
