import React, { useRef, useContext, useState, useEffect } from "react";
import { Form, Label, Input } from "reactstrap";
import JoditEditor from "jodit-react";
import { ApiContext } from "../apiContext";
import EditorConfig from "../../helper/JoditEditor";
import GlobalModal from "../../helper/PopUp";

export default function AddForm({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  contentDataChanged,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  closeModal,
  thumbnail_image_name,
}) {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    // Initialize Jodit editor
    setEditorConfig(EditorConfig);
  }, []);
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-6">
            <Label htmlFor="topic">Webinar/Event Topic</Label>
            <Input
              type="text"
              id="topic"
              name="topic"
              placeholder="Webinar/Event Topic"
              value={getInputValue("topic")}
              onChange={(event) => handleInputChange(event, "topic")}
            />
            <span className="non-valid">{formError.topic}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="url">Enter Path URL</Label>
            <Input
              type="text"
              id="url"
              name="url"
              placeholder="url"
              value={getInputValue("url")}
              onChange={(event) => handleInputChange(event, "url")}
            />
             <span className="non-valid">{formError.url}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="location">Webinar Date</Label>
            <Input
              type="date"
              id="date"
              name="date"
              value={getInputValue("date")}
              onChange={(event) => handleInputChange(event, "date")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="location">Webinar Time</Label>
            <Input
              type="time"
              format="mm:ssTZD"
              id="time"
              name="time"
              placeholder="starttime"
              value={getInputValue("time")}
              onChange={(event) => handleInputChange(event, "time")}
            />
          </div>

          
          <div className="my-3 col-md-6">
            <Label htmlFor="webiUrl">Enter Webinar URL</Label>
            <Input
              type="text"
              id="webiUrl"
              name="webiUrl"
              placeholder="invitation"
              value={getInputValue("webiUrl")}
              onChange={(event) => handleInputChange(event, "webiUrl")}
            />
          </div>
          <div className="my-3 mx-2 col-md-5">
        <Label>Choose Email Recipient</Label>
  <div className="d-flex align-items-center">
    <Input
      type="checkbox"
      id="sendMailUser"
      name="sendMailUser"
      checked={
        modalData?.sendMailUser ||
        addCase?.sendMailUser
      }
      onChange={() => {
        if (modalData) {
          setModalData((prevModalData) => ({
            ...prevModalData,
            sendMailUser:
              !prevModalData.sendMailUser,
          }));
        } else {
          setCase((prevAddCase) => ({
            ...prevAddCase,
            sendMailUser: !prevAddCase.sendMailUser,
          }));
        }
      }}
    />
    <Label for="sendMailUser" className="ms-2">User</Label>
    
    <Input
      type="checkbox"
      id="sendMailAdmin"
      name="sendMailAdmin"
      checked={
        modalData?.sendMailAdmin ||
        addCase?.sendMailAdmin
      }
      onChange={() => {
        if (modalData) {
          setModalData((prevModalData) => ({
            ...prevModalData,
            sendMailAdmin:
              !prevModalData.sendMailAdmin,
          }));
        } else {
          setCase((prevAddCase) => ({
            ...prevAddCase,
            sendMailAdmin: !prevAddCase.sendMailAdmin,
          }));
        }
      }}
      className="ms-3"
    />
    <Label for="sendMailAdmin" className="ms-2">Admin</Label>
  </div>
</div>


          <div className="my-3 col-md-12">
            <Label htmlFor="author-bio">Description</Label>
            <JoditEditor
              ref={editor}
              config={editorConfig}
              tabIndex={1}
              value={modalData?.description || ""}
              // onBlur={(newContent) =>
              //   handleInputChange(
              //     { target: { value: newContent } },
              //     "description"
              //   )
              // }
              onChange={(event) => contentDataChanged(event, "description")}
            />
            <GlobalModal  />
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="imagefile">
              Select Image (Upload only images up to 100kb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="imagefile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_image")
                  }
                  accept="image/gif, image/jpeg, image/png, image/webp*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_image_name
                      : addCase.thumbnail_image_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_image_name")
                  }
                />
              </div>
            </div>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}
