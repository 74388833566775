import "./App.css";
import Footer from "./global/Footer";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoute } from "./auth/PrivateRoute";
import LoginForm from "./Login/LoginForm";
import Dashboard from "./Components/Dashboard";
import Blog from "./Components/Blog/Blog";
import Award from "./Components/Award/Award";
import Career from "./Components/Career/Career";
import CaseStudies from "./Components/CaseStudy/CaseStudies";
import RedirectUrl from "./Components/RedirectUrl/RedirectUrl";
import Testimonials from "./Components/Testimonials/Testimonials";
import TestiVideos from "./Components/TestiVideo/TestimonialsVidoes";
import Videos from "./Components/Videos/Videos";
import Ebooks from "./Components/Ebooks/Ebooks";
import Events from "./Components/Events/Events";
import Faqs from "./Components/Faq/Faqs";
import PageVideo from "./Components/PageVIdeo/PageVideo";
import FSideBar from "./Components/FSideBar";
import { ToastContainer } from "react-toastify";
import Error from "./helper/Error";
import ProductMapping from "./Components/InterestedproductApi.js/ProductMapping";
import Glossary from "./Components/Glossary/Glossary";
import MetaTag from "./Components/MetaTag/MetaTag";
import BlockeMaillist from "./Components/Master/BlockeMaillist/BlockeMaillist";
import Webinar from "./Components/webinar/Webinar";
import Ads from "./Components/Ads/Ads";
import Email from "./Components/Email/Email";
import Leads from "./Components/Leads/Leads";
import Form from "./Components/Forms/Form";
import MainForm from "./Components/Customization/MainForm";
import Landing from "./Components/LandingPage/Landing";

function App() {
  return (
    <div className="App">
      <React.StrictMode>
        <ToastContainer />
        <Router>
          <FSideBar>
            <Routes>
              <Route path="/" element={<LoginForm />} />
              <Route path="/" element={<PrivateRoute />}>
                {/* <Route  path="/dashboard" element={<Home />} /> */}
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/ebooks" element={<Ebooks />} />
                <Route path="/casestudies" element={<CaseStudies />} />
                <Route path="/career" element={<Career />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/video" element={<TestiVideos />} />
                <Route path="/event" element={<Events />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/pagevideo" element={<PageVideo />} />
                <Route path="/urllist" element={<RedirectUrl />} />
                <Route path="/award" element={<Award />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/metaTag" element={<MetaTag />} />
                <Route path="/glossary" element={<Glossary />} />
                <Route path="/ipmapping" element={<ProductMapping />} />
                <Route path="/webinar" element={<Webinar />} />
                <Route path="/ads" element={<Ads />} />
                <Route path ="/cta" element ={<Form />} />
                <Route path="/email" element={<Email />} />
                <Route path="/leads" element={<Leads />} />
                <Route path="/landingPage" element={<Landing />} />
                <Route path = "/customization" element={<MainForm />} />
                <Route
                  path="/master/blockemaillist"
                  element={<BlockeMaillist />}
                />
                <Route
                  path="/master/careercategory"
                  element={<BlockeMaillist />}
                />
                <Route path="/master/category" element={<BlockeMaillist />} />
                <Route
                  path="/master/videoscategory"
                  element={<BlockeMaillist />}
                />
                <Route path="/master/language" element={<BlockeMaillist />} />
                <Route path="/master/country" element={<BlockeMaillist />} />
                <Route
                  path="/master/careercategory"
                  element={<BlockeMaillist />}
                />
                <Route
                  path="/master/ebookscategory"
                  element={<BlockeMaillist />}
                />
                <Route
                  path="/master/casestudiescategory"
                  element={<BlockeMaillist />}
                />
                <Route
                  path="/master/interestedproduct"
                  element={<BlockeMaillist />}
                />
                <Route path="/master/solutions" element={<BlockeMaillist />} />
                <Route
                  path="/master/videoscategory"
                  element={<BlockeMaillist />}
                />
                <Route
                  path="/master/maileventtype"
                  element={<BlockeMaillist />}
                />
                 <Route
                  path="/master/formtype"
                  element={<BlockeMaillist />}
                />

                <Route path="error" element={<Error />} />
                <Route
                  path="*"
                  element={
                    <Navigate
                      to="/error"
                      state={{ error: "Page not found" }} // You can customize the error message here
                    />
                  }
                />
              </Route>
            </Routes>
          </FSideBar>
        </Router>
      </React.StrictMode>
      <Footer />
    </div>
  );
}

export default App;
