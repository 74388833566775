import React, { useState, useRef, useEffect, useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import JoditEditor from "jodit-react";

import { ApiContext } from "../apiContext";
import Select from "react-select";
import EditorConfig from "../../helper/JoditEditor";
import GlobalModal from "../../helper/PopUp";

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  postCategory,
  country,
  language,
  contentDataChanged,
}) => {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    // Initialize Jodit editor
    setEditorConfig(EditorConfig(data.forms));
  }, []);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const { error, data } = useContext(ApiContext);


  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-12">
            <Label for="url">Blog URL</Label>
            <Input
              type="text"
              id="url"
              name="url"
              placeholder="URL"
              value={getInputValue("url")}
              onChange={(event) => handleInputChange(event, "url")}
            />
            <span className="non-valid">{formError.url}</span>
          </div>
          <div className="my-3 col-md-12">
            <Label htmlFor="category">Category</Label>
            <Select
              name="category"
              options={
                postCategory &&
                postCategory
                  .filter((option) => option.Status === true)
                  .map((option) => ({
                    value: option.Code,
                    label: option.Name,
                  }))
              }
              value={
                !modalData
                  ? selectedOptions
                  : modalData?.categoryId &&
                    modalData.categoryId.map((categoryId) => ({
                      value: categoryId,
                      label: postCategory.find(
                        (option) => option.Code === categoryId
                      )?.Name,
                    }))
              }
              onChange={(selectedOptions) => {
                if (!modalData) {
                  setSelectedOptions(selectedOptions);
                  setCase((prevData) => ({
                    ...prevData,
                    categoryId: selectedOptions.map((option) => option.value),
                    category: selectedOptions
                      .map(
                        (option) =>
                          postCategory.find(
                            (category) => category.Code === option.value
                          )?.Name
                      )
                      .join(", "),
                  }));
                } else {
                  const selectedValues = selectedOptions.map(
                    (option) => option.label
                  );
                  handleInputChange(
                    {
                      target: { value: selectedValues.join(", ") },
                    },
                    "category"
                  );
                  setModalData((prevData) => ({
                    ...prevData,
                    categoryId: selectedOptions.map((option) => option.value),
                  }));
                }
              }}
              isMulti
              isSearchable
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="country">Country</Label>
            <Select
              name="country"
              options={
                country &&
                country
                  .filter((option) => option.Status === true)
                  .map((option) => ({
                    value: option.Code, 
                    label: option.Name,
                  }))
              }
              value={
                !modalData
                  ? selectedCountry
                  : modalData?.countryId &&
                    modalData.countryId.map((countryId) => ({
                      value: countryId,
                      label: country.find((option) => option.Code === countryId)
                        ?.Name,
                    }))
              }
              onChange={(selectedCountry) => {
                if (!modalData) {
                  setSelectedCountry(selectedCountry);
                  setCase((prevData) => ({
                    ...prevData,
                    countryId: selectedCountry.map((option) => option.value),
                    country: selectedCountry
                      .map(
                        (option) =>
                          country.find(
                            (country) => country.Code === option.value
                          )?.Name
                      )
                      .join(", "),
                  }));
                } else {
                  const selectedValues = selectedCountry.map(
                    (option) => option.label
                  );
                  handleInputChange(
                    {
                      target: { value: selectedValues.join(", ") },
                    },
                    "country"
                  );
                  setModalData((prevData) => ({
                    ...prevData,

                    countryId: selectedCountry.map((option) => option.value),
                  }));
                }
              }}
              isMulti
              isSearchable
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="category">Language</Label>
            <Select
              name="category"
              options={
                language &&
                language
                  .filter((option) => option.Status === true)
                  .map((option) => ({
                    value: option.Code,
                    label: option.Name,
                  }))
              }
              value={
                !modalData
                  ? selectedLanguages
                  : modalData?.languageId &&
                    modalData.languageId.map((languageId) => ({
                      value: languageId,
                      label: language.find(
                        (option) => option.Code === languageId
                      )?.Name,
                    }))
              }
              onChange={(selectedLanguages) => {
                if (!modalData) {
                  setSelectedLanguages(selectedLanguages);
                  setCase((prevData) => ({
                    ...prevData,
                    language: selectedLanguages
                      .map(
                        (option) =>
                          language.find(
                            (language) => language.Code === option.value
                          )?.Name
                      )
                      .join(", "),
                    languageId: selectedLanguages.map((option) => option.value),
                  }));
                } else {
                  const selectedValues = selectedLanguages.map(
                    (option) => option.label
                  );
                  handleInputChange(
                    {
                      target: { value: selectedValues.join(", ") },
                    },
                    "language"
                  );
                  setModalData((prevData) => ({
                    ...prevData,
                    languageId: selectedLanguages.map((option) => option.value),
                  }));
                }
              }}
              isMulti
              isSearchable
            />
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="name">Blog Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={getInputValue("name")}
              onChange={(event) => handleInputChange(event, "name")}
            />
            <span className="non-valid">{formError.name}</span>
          </div>

      

          <div className="my-3 col-md-6">
            <Label htmlFor="image-text">Blog Image alt text</Label>
            <Input
              type="text"
              id="image-text"
              name="imgalttext"
              placeholder="Image ALT Text"
              value={getInputValue("imgalttext")}
              onChange={(event) => handleInputChange(event, "imgalttext")}
            />
            <span className="non-valid">{formError.imgalttext}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label for="imagefile">
              Select Blog Image (Upload only images up to 100kb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="imagefile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_image")
                  }
                  accept="image/gif, image/jpeg, image/png, image/webp*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_image_name
                      : addCase.thumbnail_image_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_image_name")
                  }
                />
              </div>
            </div>
            <span className="non-valid">{formError.thumbnail_image}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="name">Author</Label>
            <Input
              type="text"
              id="author"
              name="author"
              placeholder="Author"
              value={getInputValue("author")}
              onChange={(event) => handleInputChange(event, "author")}
            />
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="name">Author Bio</Label>
            <Input
              type="textarea"
              id="author-bio"
              name="authorbio"
              placeholder="Author Bio"
              value={getInputValue("authorBio")}
              onChange={(event) => handleInputChange(event, "authorBio")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label for="url">Short Description</Label>
            <Input
              type="textarea"
              id="short_Description"
              name="short_Description"
              placeholder="Short Discription"
              value={getInputValue("short_Description")}
              onChange={(event) => handleInputChange(event, "short_Description")}
            />
            <span className="non-valid">{formError.shortdescription}</span>
          </div>

          <div className="my-3 col-md-12">
            <Label for="author-bio">Description</Label>
            <JoditEditor
              ref={editor}
              config={editorConfig}
              value={modalData?.description || ""}
              onChange={(event) => contentDataChanged(event, "description")}
            />
            <GlobalModal  />
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddForm;
