import React, { useState, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import AddForm from "./AddForm";
import { getToken } from "../../auth/AuthService";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { imageUpload } from "../ImageUpload";
import notify from "../../helper/Notify";
import { useLoader } from "../../helper/Loader";
import { isSpecialCharacter } from "../../helper/CommonFunction";

const Service = ({ closeModal, modalData, setModalData }) => {
  const [formError, setFormError] = useState({});
  const { error, setData} = useContext(ApiContext);
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [file, setFile] = useState(null);
  const [contentError, setContentError] = useState(null);
  const{setIsLoader} = useLoader();
  const fieldsArray = ["description"];
  const [dataSave, setDataSave] = useState({})
  const apiBaseUrl = window.API_CONFIG.All_Api.ads;
  const token = getToken();
  const imgDir = "ads-images";
  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  const [addCase, setCase] = useState({
    title: "",
   ShortDescription: "",
    code: generateUUID(),
    thumbnail_image: "",
  });
  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  /*---- change input field function ----*/

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };
 // For Jodit Editor use sep. datachange function
 const contentDataChanged = (data, field) => {
  if (data.includes("http:")) {
    setContentError("Please use secure 'https' URLs only");
    return;
  }
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = data;
  const h1Count = tempDiv.getElementsByTagName("h1").length;
  if (h1Count > 1) {
    setContentError("Only one <h1> tag is permitted.");
    return;
  }

  setContentError(null);

    if (modalData) {
      setDataSave((prevDataSave) => ({
        ...prevDataSave,
        [field]: data,
      }));
    } else {
    setCase((prevAddCase) => ({
      ...prevAddCase,
      [field]: data,
    }));
  }
};

  /* -----handleImage Upload function ----*/

  const handleImageUpload = async (event, field) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 100 * 1024;
    if (selectedFile.size > fileSizeLimit) {
      notify.error("File is too big");
      return;
    }
    try {
      const base64Data = await convertBase64(selectedFile);
      setThumbnailImage(base64Data);
      setFile(selectedFile);
      console.log(file);
      if (modalData) {
        setModalData({
          ...modalData,
          [`${field}_name`]: selectedFile.name,
          [field]: base64Data,
        });
      } else {
        setCase({
          ...addCase,
          [`${field}_name`]: selectedFile.name,
          [field]: base64Data,
        });
      }
    } catch (error) {
      console.log(error);
      // Handle error while converting to base64
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["title"];
    const validatefield = (field) => {
      if (!modalData && addCase[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
      if (modalData && modalData[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    };
    fieldarr.forEach(validatefield);

    return errors;
  };

  const handleClear = () => {
    const clearfields = {
      title: "",
      ShortDescription: "",
      thumbnail_image_name: "",
      file: null,
      status: false,
      description: "",
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };
  async function processAndUploadImages(modalData, addCase, fieldsArray) {
    for (const field of fieldsArray) {
      const htmlContent = modalData ? modalData[field] : addCase[field];
      if (!htmlContent) {
        continue; // Skip to the next field
      }
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      const imgElements = Array.from(tempDiv.querySelectorAll("img"));

      const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
        return new Blob([byteArray], { type: mimeType });
      };

      const processImage = async (img) => {
        const src = img.getAttribute("src");
        if (src && src.startsWith("data:image/")) {
          const parts = src.split(";");
          const mimeType = parts[0].split(":")[1];
          const imageData = parts[1].split(",")[1];
          const blob = base64ToBlob(imageData, mimeType);
          const file = new File([blob], "image.png", { type: mimeType });

          const formData = new FormData();
          formData.append("upload", file);
          formData.append(
            "ckCsrfToken",
            "2fDpPNU1D4C9z19x1f0XZTAaH6nZlR8DoiH950V5"
          );

          try {
            const response = await fetch(window.API_CONFIG.imageStringUrl, {
              method: "POST",
              body: formData,
            });

            if (response.ok) {
              const data = await response.json();
              const imgUrl = data.url;
              img.setAttribute("src", imgUrl);
            } else {
              console.error("Error uploading image:", response.statusText);
            }
          } catch (error) {
            console.error("Error processing image:", error);
          }
        }
      };

      const processImagesSequentially = async () => {
        for (const img of imgElements) {
          await processImage(img);
        }
        if (modalData) {
          modalData[field] = tempDiv.innerHTML;
        } else {
          addCase[field] = tempDiv.innerHTML;
        }
      };

      await processImagesSequentially();
    }
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const formErrors = validateFormFields();

      if (Object.keys(formErrors).length > 0) {
        setFormError(formErrors);
        notify.warning();
        return;
      }
      setFormError("");
      setIsLoader(true)

      if (modalData) {
        const updatedModalData = { ...modalData, ...dataSave };
        await processAndUploadImages(updatedModalData, null, fieldsArray);
        const abc = await imageUpload(
          modalData.title,
          modalData.thumbnail_image,
          imgDir,
          token
        );
        updatedModalData.thumbnail_image = abc;
        const updatedData = {
          ...updatedModalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };

        const response = await axios.get(apiBaseUrl, {
          headers: {
            "x-access-token": token,
          },
        });

        const careerData = response.data.data?.ViewModels || [];
        const updatedCareerData = careerData.map((item) =>
          item.code === modalData.code ? { ...item, ...updatedData } : item
        );
        const duplicateItem = careerData.find(
          (item) =>
            item.title === modalData.title && item.code !== modalData.code
        );

        if (duplicateItem) {
          notify.error("Data is a duplicate and cannot be updated.");
          return;
        }
        if(contentError){
          notify.error(contentError);
          return;
        }

        if (isSpecialCharacter(modalData.title.charAt(0))){
          notify.error("Title should not start with special character");
             return;
        }

        const updateResponse = await axios.post(
          apiBaseUrl,
          { ViewModels: updatedCareerData },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "x-access-token": token,
            },
          }
        );
        if (updateResponse.data.success === true){
        setData((prevData) => ({
          ...prevData,
          ads: updatedCareerData,
        }));

        closeModal();
        notify.success(updateResponse.data.message);

        setCase({});
      }
      else{
        notify.error(updateResponse.data.message);
      }
      } else {
        // Adding a new data entry
        await processAndUploadImages(null, addCase, fieldsArray);
        const abc = await imageUpload(
          addCase.title,
          addCase.thumbnail_image,
          imgDir,
          token
        );
        addCase.thumbnail_image = abc;
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const oldData = response.data.data?.ViewModels || [];
          const duplicateItem = oldData.find(
            (item) => item.title === addCase.title
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be added.");
            return;
          }

          if(contentError){
            notify.error(contentError);
            return;
          }

          if (isSpecialCharacter(addCase.title.charAt(0))){
            notify.error("Title should not start with special character");
               return;
          }

          const updatedData = [...oldData, updatedBlogData];

          const addResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );
          if(addResponse.data.success === true){
          console.log(addResponse.data);
          setData((prevData) => ({
            ...prevData,
            ads: updatedData,
          }));

          closeModal();
          notify.success(addResponse.data.message);
          setCase({});
        }
        else{
          notify.error(addResponse.data.message);
        }

          // Clear the addCase state for the next use
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error(error.message);
    }
    finally{
      setIsLoader(false);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit Ads" : "Add New Ads"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleImageUpload={handleImageUpload}
                handleInputChange={handleInputChange}
                contentDataChanged={contentDataChanged}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                closeModal={closeModal}
                thumbnail_image_name={getInputValue("thumbnail_image_name")}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Service;
