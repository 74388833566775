import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../apiContext";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import { getToken } from "../../auth/AuthService";
import Service from "./Service";
import ViewCase from "./ViewCase";
import DeleteBox from "../DeleteBox";
import notify from "../../helper/Notify";
import { useLoader } from "../../helper/Loader";
import { parseDate } from "../../helper/CommonFunction";
import htmlTruncate from "html-truncate";

function CaseApi({ page = 1, pageSize, searchQuery, totalLength }) {
  const { data, setData, error } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const closeModal = () => setshowModal(false);
  const modalClose = () => setEditModal(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const apiBaseUrl = window.API_CONFIG.All_Api.casestudies;
  const token = getToken();
  const {setIsLoader} = useLoader();

  useEffect(() => {
    try {
      if (!data.casestudies || data.casestudies.length === 0) {
        throw new Error("No data available");
      }

      const filteredData = data.casestudies.filter((item) => {
        const name = item?.title ?? "";
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      });

      const sortedData = filteredData.sort(
        (a, b) =>{
        const blogA = a;
        const blogB = b;
        const dateA = blogA.updatedDate ? new Date(blogA.updatedDate) : new Date(blogA.createdDate);
        const dateB = blogB.updatedDate ? new Date(blogB.updatedDate) : new Date(blogB.createdDate);
        return dateB - dateA; 
    });

      setFilteredBlog(sortedData);
      totalLength(filteredData.length);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, [data.casestudies, searchQuery, page, pageSize, totalLength]);


  function generateApiUrl(fileName) {
    return `${apiBaseUrl}/${fileName}`;
  }

  /* Handle Remove function */
  const confirmDelete = async () => {
    setShowDeleteModal(false);
    const apiUrl = `${apiBaseUrl}/${deleteCandidate}`;
    setIsLoader(true)
    try {
      // Send the delete request and wait for it to complete
      const response = await axios.delete(apiUrl);
      console.log("Delete response:", response.data);
      const updatedDataResponse = await axios.get(apiBaseUrl, {
        headers: {
          "x-access-token": token,
        },
      });
      const updatedDataFromServer =
        updatedDataResponse.data.data?.ViewModels || [];
      setData((prevData) => ({
        ...prevData,
        casestudies: updatedDataFromServer,
      }));
      notify.success("Data removed successfully");
    } catch (error) {
      // Handle any errors here
      setIsLoader(false)
      console.error("Error deleting data:", error);
    }
    setIsLoader(false)
  };
  const handleRemover = async (id) => {
    setDeleteCandidate(id);
    setShowDeleteModal(true);
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteCandidate(null);
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const items = filteredBlog
      .slice(startIndex, endIndex)
      .map((viewModel, index) => {
        const id = `item-${index}`,
          apiUrl = generateApiUrl(viewModel.fileName);

        const fetchData = async () => {
          try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            // Process the fetched data here
            console.log(data);
            setModalData(data.data);
          } catch (error) {
            // Handle any errors that occur during the fetch
            console.error(error);
          }
        };
        return (
          <tr key={id}>
            <td
              title="View"
              onClick={() => {
                setshowModal(true);
                fetchData();
              }}
            >
              <FaEye />
            </td>

            <td
              title="Edit"
              onClick={() => {
                setEditModal(true);
                fetchData();
              }}
            >
              <FaEdit />
            </td>
            <td
              title="Delete"
              onClick={() => handleRemover(viewModel.fileName)}
            >
              <FaTrashAlt />
            </td>
            {/* {showModal && <AddCase closeModal={closeModal} />} */}

            <td width="25%" title={viewModel.title}>
              {viewModel.title && viewModel.title.length > 30
                ? `${viewModel.title.substring(0, 30)}...`
                : viewModel.title || "Title not available"}
            </td>
            <td width="33%">
              {viewModel.description ? (
                 <div
                 dangerouslySetInnerHTML={{
                   __html: htmlTruncate(viewModel.description, 50), 
                 }}
               
                 />
                
              ) : (
                "Description not available"
              )}

            </td>

            <td width="16%">
              {viewModel.updatedDate || viewModel.createdDate}
            </td>
            <td width="10%">
              <span
                className={`status ${viewModel.status ? "active" : "inactive"}`}
              >
                {viewModel.status ? "active" : "inactive"}
              </span>
            </td>
            {showModal && (
              <ViewCase Modalclose={closeModal} modalData={modalData} />
            )}
            {EditModal && (
              <Service
                closeModal={modalClose}
                modalData={modalData}
                setModalData={setModalData}
              />
            )}
          </tr>
        );
      });

    const caseItems = (
      <>
        <tbody>
          {filteredBlog.length === 0 ? (
            <tr>
            <td classname ="noFound" colSpan="12">No data found</td>
            </tr>
          ) : (
            items
          )}
          {showDeleteModal && (
            <DeleteBox Modalclose={cancelDelete} handleDelete={confirmDelete} />
          )}
        </tbody>
      </>
    );
    return caseItems;
  }
}

export const CaseStudiesLength = () => {
  const { data } = useContext(ApiContext);
  return data.casestudies.length;
};

export default CaseApi;
