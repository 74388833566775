import PopUp from "./PopUp";

const EditorConfig = (data = {}) => {

  
  let formattedData = {};

  

  return {
    uploader: {
      insertImageAsBase64URI: true,
      imageAlt: "",
    },
    pasteFormat: "html",
    buttons: [
      "source",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "eraser",
      "|",
      "ul",
      "ol",
      "|",
      "fontsize",
      "font",
      "paragraph",
      "classSpan",
      "lineHeight",
      "|",
      "subscript",
      "superscript",
      "image",
      "video",
      "|",
      "spellcheck",
      "|",
      "cut",
      "copy",
      "paste",
      "selectall",
      "copyformat",
      "|",
      "hr",
      "table",
      "link",
      "symbols",
      "|",
      "outdent",
      "indent",
      "align",
      "|",
      "brush",
      "|",
      "undo",
      "redo",
      "|",
      "find",
      "|",
      "fullsize",
      "preview",
      "print",
      "about",
    ],
    buttonsXS: [
      "bold",
      "image",
      "|",
      "brush",
      "paragraph",
      "|",
      "align",
      "|",
      "undo",
      "redo",
      "|",
      "eraser",
      "dots",
    ],
    controls: {
      font: {
        list: {
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Poppins,sans-serif": "Poppins",
          "Roboto,sans-serif": "Roboto",
          "Roboto-Bold,sans-serif": "Roboto-Bold",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "Times New Roman,Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana",
        },
      },
    },
    extraButtons: [
      {
        iconURL:"https://cdn-icons-png.flaticon.com/512/2991/2991106.png",
        tooltip: "Select Call Back form ",
        exec: (editor) => {
          const event = new CustomEvent("openModal", { detail: { editor, type: "formTitle" } });
        window.dispatchEvent(event); //Trigger global event
        },
      },

      {
        iconURL: "https://cdn-icons-png.flaticon.com/512/6015/6015685.png",
        tooltip: "Select Your Customized form fields",
        exec: (editor) => {
          const event = new CustomEvent("openModal", { detail: { editor, type: "formField" } });
          window.dispatchEvent(event); // Trigger global event
        },
      },
    ],
    alt: true,
  };
};

export default EditorConfig;
