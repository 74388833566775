import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BlogLength } from "./Blog/BlogApi";
import { CaseStudiesLength } from "./CaseStudy/CaseApi";
import { CareerLength } from "./Career/CareerApi";
import { EventLength } from "./Events/EventApi";
import { AwardLength } from "./Award/AwardApi";
import { TestiLength } from "./Testimonials/TestiApi";
import { UrlLength } from "./RedirectUrl/UrlApi";
import { VideoLength } from "./TestiVideo/TestiVideoApi";
import { VideosLength } from "./Videos/VideosApi";
import { EbookLength } from "./Ebooks/EbookApi";
import { PageVideoLength } from "./PageVIdeo/PageVideoApi";
import { FaqsLength } from "./Faq/FaqsApi";
import { GlossaryLength } from "./Glossary/GlossaryApi";
import { WebinarLength } from "./webinar/WebinarApi";
import { AdsLength } from "./Ads/AdsApi";

import { MetaTagLength } from "./MetaTag/MetaTagApi";
import { ViewModels } from "./SidebarFormat";
import { InterestedproductLength } from "./InterestedproductApi.js/ProductAPi";
import { EmailLength } from "./Email/EmailApi";
import { LeadLength } from "./Leads/LeadsApi";
import { FormLength } from "./Forms/FormApi";
import { LadingLength } from "./LandingPage/LandingApi";

export default function Sidebar({ children }) {
  return (
    <>
      <div className="row">
        {ViewModels.map((item, index) => (
          <div className="menu-item col-md-3" key={index}>
            <NavLink
              to={item.Href}
              className="link-data"
              active="active"
              key={index}
            >
              <div className="link-icon">
                <span>{item.Icon}</span>
              </div>
              <div className="text-box">
                <h4 className="item-length">
                  {(() => {
                    switch (item.State) {
                      case "blog":
                        return <BlogLength />;
                      case "casestudies":
                        return <CaseStudiesLength />;
                      case "career":
                        return <CareerLength />;
                      case "event":
                        return <EventLength />;
                      case "award":
                        return <AwardLength />;
                      case "testimonials":
                        return <TestiLength />;
                      case "urllist":
                        return <UrlLength />;
                      case "video":
                        return <VideoLength />;
                      case "videos":
                        return <VideosLength />;
                      case "ebooks":
                        return <EbookLength />;
                      case "ads":
                        return <AdsLength />;
                      case "pagevideo":
                        return <PageVideoLength />;
                      case "faqs":
                        return <FaqsLength />;
                      case "glossary":
                        return <GlossaryLength />;
                      case "ipmapping":
                        return <InterestedproductLength />;
                      case "webinar":
                        return <WebinarLength />;
                      case "metaTag":
                        return <MetaTagLength />;
                        case "email" :
                        return <EmailLength />
                        case "leads" :
                          return <LeadLength />
                        case "cta" :
                          return <FormLength />
                        case "customization":
                           return <FormLength />
                        case "landingPage":
                          return <LadingLength />
                      default:
                        return null;
                    }
                  })()}
                </h4>
                <p className="link-text">{item.Title}</p>
              </div>
            </NavLink>
          </div>
        ))}
      </div>
    </>
  );
}
