import React, { useState, useContext, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { ApiContext } from "../apiContext";
import axios from "axios";
import { getToken } from "../../auth/AuthService";
import { v4 as uuidv4 } from "uuid";
import AddFieldAccordion from "./AddForm";
import { Table } from "reactstrap";
import { useLoader } from "../../helper/Loader";


export default function MainForm() {
    const { data, setData } = useContext(ApiContext);
  const [selectedFormType, setSelectedFormType] = useState("");
  const apiBaseUrl = window.API_CONFIG.All_Api.formType;
  const {setIsLoader} = useLoader();
  const [selectId, setSelectId] = useState("")
  const [selectedForm, setSelectedForm] = useState(null);
  const token = getToken();
  const formTypes = data?.formType
  ? [...new Set(data.formType.filter((form) => form.Status === true).map((form) => form.Name))]
  : [];

    useEffect(() => {
        if (formTypes.length > 0) {
          setSelectedFormType(formTypes[0]);
          const selected = data.formType.find((form) => form.Name === formTypes[0]);
          setSelectId(selected?.Code || "");
        }
      }, []);

      useEffect(() => {
        if (selectedFormType && selectId) {
            const form = data?.formField?.find(
                (form) => form.formType === selectedFormType && form.formId === selectId
            );
            setSelectedForm(form || null);
        }
    }, [selectedFormType, selectId, data?.formField]);

    
    const handleDeleteField = async (fieldName) => {
      const deletedData = {
          formId: selectId,
          formType: selectedFormType,
          formfields: [fieldName],  
      };
      setIsLoader(true);
  
      try {
          await axios.delete(window.API_CONFIG.All_Api.formField, {
              headers: { 
                  "Content-Type": "application/json", 
                  "x-access-token": token 
              },
              data: deletedData,  
          });
  
          setData((prevData) => {
              return {
                  ...prevData,
                  formField: prevData.formField.map((form) => 
                      form.formType === selectedFormType 
                          ? {
                              ...form,
                              formfields: form.formfields.filter((f) => f !== fieldName) 
                            }
                          : form
                  )
              };
          });
  
          // Wait for state update
          setTimeout(() => console.log(data.formField), 0);
  
      } catch (error) {
          console.log("Error Deleting the Data:", error);
      } finally {
          setIsLoader(false);
      }
  };
  
  

  return (
    <section className="dash-section">
      <div className="container p-0">
        <div className="row p-0 m-0 user/dashboarduser/dashboard">
          <div className="col-lg-12 col-md-12 col-sm-12 right-content">
          <div className="head-name">
          <h3>Customize Form Fields</h3>
              </div>
            <div className="bottom" style={{textAlign: "left"}}>
            <div className="top-button col-md-4">
              <label className="text-left" style={{fontWeight: 500, paddingBottom: "10px"}}>Select Form Type:</label>
              <select
  className="form-control"
  value={selectedFormType}
  onChange={(e) => {
    setSelectedFormType(e.target.value);
    const selected = data.formType.find((form) => form.Name === e.target.value);
    setSelectId(selected?.Code || ""); 
}}
>
    {console.log(selectId)}
  {formTypes.map((type, index) => (
    <option key={index} value={type}>{type}</option>
  ))}
</select>
            </div>
            <h4 style={{padding: "20px 0 0 0", fontFamily: "Roboto", fontSize: "20px"}}>Current Fields</h4>
            {selectedForm && (
              <Table hover className="table mt-3" style={{border: "1px solid #ddd "}}>
                <thead>
                  <tr>
                    <th  width="250px">Name</th>
                    <th  width="250px">Key</th>
                    <th  width="150px">Type</th>
                    <th  width="120px">Mandatory</th>
                    <th  width="100px">Action</th>
                  </tr>
                </thead>
                <tbody>
                {selectedForm.formfields?.map((field) => (
                    <tr key={field.name}>
                      <td>{field.label}</td>
                      <td>{field.name}</td>
                      <td>
  <span className="badge border border text-dark bg-transparent">{field.type}</span>
</td>

                      <td>
  <span className={`badge ${field.required ? "bg-success" : "bg-danger"}`}>
    {field.required ? "Yes" : "No"}
  </span>
</td>

                      <td>
  <FaTrash
    color="red"
    style={{ cursor: "pointer", color: "#ef4444" }} 
    onMouseOver={(e) => (e.target.style.color = "#b91c1c")} 
    onMouseOut={(e) => (e.target.style.color = "#ef4444")}
    onClick={() => handleDeleteField(field)}
  />
</td>

                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
           <AddFieldAccordion 
  selectedFormType={selectedFormType} 
  selectId={selectId} 
/>

          </div>
        </div>
      </div>
      </div>
    </section>
  );
}
