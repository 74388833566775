import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../apiContext";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa";
import ViewBlog from "./ViewBlog";
import axios from "axios";
import { getToken } from "../../auth/AuthService";
import Service from "./Service";
import DeleteBox from "../DeleteBox";
import notify from "../../helper/Notify";
import { useLoader } from "../../helper/Loader";
import { parseDate } from "../../helper/CommonFunction";

export function BlogApi({ page = 1, pageSize, searchQuery, totalLength }) {
  const API = window.API_CONFIG.All_Api.blog;
  const { data, error, setData } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [modalData, setModalData] = useState("");
  const token = getToken();
  const [showModal, setShowModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const {setIsLoader} = useLoader();

  const closeModal = () => setShowModal(false);
  const modalClose = () => setEditModal(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const showDetail = async (id) => {
    try {
      const response = await fetch(`${API}/${id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setModalData(data.data);
      setSelectedBlogId(id); 
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
        if (!data.blog || data.blog.length === 0) {
            throw new Error("No data available");
        }

        const filteredData = data.blog.filter(blogItem => {
            try {
                const temp = JSON.parse(blogItem);
                return temp.name && temp.name.toLowerCase().includes(searchQuery.toLowerCase());
            } catch (error) {
                console.error("Error parsing blog item:", error);
                return false; // Exclude invalid JSON items
            }
        });

        const sortedData = filteredData.sort((a, b) => {
            try {
                const blogA = JSON.parse(a);
                const blogB = JSON.parse(b);
                const dateA = blogA.updatedDate ? parseDate(blogA.updatedDate) : parseDate(blogA.createdDate);
                const dateB = blogB.updatedDate ? parseDate(blogB.updatedDate) : parseDate(blogB.createdDate);
                return dateB - dateA;
            } catch (error) {
                console.error("Error parsing date in blog item:", error);
                return 0; // Maintain current order if date parsing fails
            }
        });

        setFilteredBlog(sortedData);
        totalLength(filteredData.length);
    } catch (error) {
        setFilteredBlog([]);
        totalLength(0);
        console.error("Error fetching or processing data:", error);
    }
}, [data.blog, searchQuery, page, pageSize, totalLength]);

  
  /* Handle Remove function */

  const confirmDelete = async () => {
   
    setShowDeleteModal(false);
    const apiUrl = `${API}/${deleteCandidate}`;
    setIsLoader(true);
    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          "x-access-token": token,
        },
      });

      console.log("Delete response:", response.data);

     
      const updatedDataResponse = await axios.get(API, {
        headers: {
          "x-access-token": token,
        },
      });
      setData((prevData) => ({
        ...prevData,
        blog: updatedDataResponse.data.data,
      }));
      notify.success("Data removed successfully");
    } catch (error) {
      setIsLoader(false);
      console.error("Error deleting data:", error);
    }
    setIsLoader(false);
  };
  const handleRemover = async (id) => {
    setDeleteCandidate(id);
    setShowDeleteModal(true);
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteCandidate(null);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const items = filteredBlog
      .slice(startIndex, endIndex)
      .map((blogs, index) => {
        const id = `item-${index}`;
        const temp = JSON.parse(blogs);
        const statusText = temp.status ? "active" : "inactive";
        return (
          <tr key={id}>
            <td
              title="View"
              onClick={() => {
                setShowModal(true);
                showDetail(temp.id);
              }}
            >
              <FaEye />
            </td>
            <td
              title="Edit"
              onClick={() => {
                showDetail(temp.id);
                setEditModal(true);
              }}
            >
              <FaEdit />
            </td>
            <td
              title="Delete"
              onClick={() => {
                handleRemover(temp.id);
              }}
            >
              <FaTrashAlt />
            </td>
            <td  title={temp.name}>
              {temp.name && temp.name.length > 40
                ? `${temp.name.substring(0, 40)}...`
                : temp.name || "Title not available"}
            </td>
            <td>
              {temp.author && temp.author.length > 20
                ? `${temp.author.substring(0, 20)}...`
                : temp.author || "Author not available"}
            </td>
            <td title={temp.category}>
              {temp.category?.length > 30
                ? `${temp.category.substring(0, 30)}...`
                : temp.category}
            </td>
            <td>{temp.updatedDate ? temp.updatedDate : temp.createdDate}</td>
            <td>
              <span className={`status ${statusText}`}> {statusText}</span>
            </td>
            {showModal && (
              <ViewBlog Modalclose={closeModal} modalData={modalData} />
            )}

            {EditModal && (
              <Service
                closeModal={modalClose}
                modalData={modalData}
                setModalData={setModalData}
              />
            )}
          </tr>
        );
      });

    return (
      <>
        <tbody>
          {filteredBlog.length === 0 ? (
            <tr>
            <td classname ="noFound" colSpan="12">No data found</td>
            </tr>
          ) : (
            items
          )}
          {showDeleteModal && (
            <DeleteBox Modalclose={cancelDelete} handleDelete={confirmDelete} />
          )}
        </tbody>
      </>
    );
  }
}

export const BlogLength = () => {
  const { data } = useContext(ApiContext);
  return data.blog.length;
};

export default BlogApi;
