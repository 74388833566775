import React, { createContext, useState, useEffect } from "react";

export const ApiContext = createContext();

export function ApiContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    blog: [],
    casestudies: [],
    career: [],
    event: [],
    award: [],
    testimonials: [],
    urllist: [],
    video: [],
    videos: [],
    ebooks: [],
    pagevideo: [],
    faqs: [],
    glossary: [],
    interestedproduct: [],
    metaTag: [],
    postCategory: [],
    language: [],
    country: [],
    caseStudiesCategory: [],
    careerCategory: [],
    videoscategory: [],
    ipmapping: [],
    solutions: [],
    webinar: [],
    ads: [],
    forms:[],
    maileventtype: [],
    email: [],
    formField:[],
    formType:[],
    landingPage:[],
    error: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoints = Object.values(window.API_CONFIG.All_Api); 

        const fetchPromises = endpoints.map(async (endpoint) => {
          try {
            const response = await fetch(endpoint);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const responseData = await response.json();
            let viewModel;

            if (responseData && responseData.data) {
              if (responseData.data.ViewModels) {
                viewModel = responseData.data.ViewModels;
              } else {
                viewModel = responseData.data;
              }
            }
            return viewModel;
          } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
            return null;
          }
        });

        const fetchedData = await Promise.all(fetchPromises);

        const updatedData = Object.fromEntries(
          Object.keys(window.API_CONFIG.All_Api).map((endpoint, index) => [
            endpoint,
            fetchedData[index] || [], 
          ])
        );

        setData((prevData) => ({ ...prevData, ...updatedData }));
      } catch (error) {
        setData((prevData) => ({ ...prevData, error }));
      } finally {
        setIsLoading(false); 
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div id="loading-overlay" className="preloader-div-overlay ng-scope">
        <div className="preloader-div">
          <div className="preloader-wrapper big">
            <div >
              <img
                src={require("../images/preloader.gif")}
                style={{ width: "100px" }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ApiContext.Provider value={{ data, setData, setIsLoading }}>
      {children}
    </ApiContext.Provider>
  );
}

export default ApiContextProvider;
