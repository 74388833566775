import React, { useEffect, useState, useContext } from "react";
import { ApiContext } from "../Components/apiContext";

const GlobalModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);
  const [formattedData, setFormattedData] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const { error, data } = useContext(ApiContext);
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let sourceData = [];

      if (modalType === "formTitle" && data.forms) {
        sourceData = Object.keys(data.forms).map((key) => ({
          title: data.forms[key]?.title || "",
          code: data.forms[key]?.code ? `###FormId: ${data.forms[key].code}###` : "",
        }));
      } else if (modalType === "formField" && data.formField) {
        sourceData = Object.keys(data.formField).map((key) => ({
          title: data.formField[key]?.formType || "",
          code: data.formField[key]?.formId ? `###FormFields: ${data.formField[key].formId}###` : "",
        }));
      }

      setFormattedData(sourceData);
    }
  }, [data, modalType]);

  useEffect(() => {
    const handleOpenModal = (event) => {
      setEditorInstance(event.detail.editor);
      setModalType(event.detail.type);
      setShowModal(true);
    };

    window.addEventListener("openModal", handleOpenModal);
    return () => {
      window.removeEventListener("openModal", handleOpenModal);
    };
  }, [modalType]);

  const handleSelection = () => {
    if (editorInstance && selectedTitle) {
      const selectedItem = formattedData.find((item) => item.title === selectedTitle);
      if (selectedItem) {
        editorInstance.s.insertHTML(`${selectedItem.code}`);
      }
    }
    setShowModal(false);
  };

  return (
    <div className={`modal fade ${showModal ? "show d-block" : ""}`} style={{ background: "rgba(0,0,0,0.5)" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header" style={{ padding: "10px 20px" }}>
            <h5 className="modal-title">
              {modalType === "formTitle" ? "Select a Form" : "Select a Form Field"}
            </h5>
            <button type="button" className="close" onClick={() => setShowModal(false)} style={{ marginTop: "0px", padding: "5px 10px" }}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {formattedData.length > 0 ? (
              <ul className="list-group">
                {formattedData.map((item) => (
                  <li key={item.title} className="list-group-item d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{item.title}</strong>
                      <br />
                      <span className="text-muted">{item.code}</span>
                    </div>
                    <input
                      type="radio"
                      name="formTitle"
                      value={item.title}
                      checked={selectedTitle === item.title}
                      onChange={() => setSelectedTitle(item.title)}
                      style={{ transform: "scale(0.9)" }}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <p>No data available</p>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary ms-2" onClick={handleSelection} disabled={!selectedTitle}>
              Insert
            </button>
            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalModal;
